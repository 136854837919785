// sass-lint:disable-all

.grid12-1,
.grid12-2,
.grid12-3,
.grid12-4,
.grid12-5,
.grid12-6,
.grid12-7,
.grid12-8,
.grid12-9,
.grid12-10,
.grid12-11,
.grid12-12,
.grid-full {
    @include breakpoint(0, 's') {
        width: 100% !important;
        margin-left: 0;
        margin-right: 0;
    }
}
