.product-item {
    .price-box {
        .price {
            @include rem(font-size, 22px);
            color: map-get($color, 'dark-grey');
        }
    }
}

.product-info-main {
    .price-box {
        .price-wrapper {
            .price {
                @include rem(font-size, 22px);
                color: map-get($color, 'dark-grey');
            }
        }
    }
}

.product-items {
    .item {
        .price-box {
            .price {
                @include rem(font-size, 22px);
                color: map-get($color, 'dark-grey');
            }
        }
    }
}

.price-wrapper {
    @include rem(font-size, 22px);
    color: map-get($color, 'dark-grey');
}
