.opc-progress-bar {
    .opc-progress-bar-item._active {
        &::before {
            background-color: map-get($color, 'red');
        }

        > span::before {
            background-color: map-get($color, 'red');
        }
    }
}
