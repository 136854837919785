// This file will include all the variables such as site colours, breakpoints etc.
$breakpoints: (
    'xs': 480px,
    's': 768px,
    'm': 990px,
    'l': 1024px,
    'xl': 1200px,
    'xxl': 1440px
);

$color: (
    'red': #b20933,
    'dark-grey': #333
);
