// sass-lint:disable-all
/*
Easiest way to set the font to Cabin for all elements which are not icons
*/
:not(.ic) {
  font-family: museo, serif !important;
  font-weight: 300 !important;
}

/*
Font weight 500 if italic
*/
i,
em {
  &:not(.ic) {
    font-family: museo, serif !important;
    font-weight: 500 !important;
  }
}

/*
Font weight 700 if headers
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  &:not(.ic),
  > *:not(.ic) {
    font-family: museo, serif !important;
    font-weight: 700 !important;
  }
}

/*
Set font family to ThemeIcons for icons to display
*/
.sep {
  font-family: ThemeIcons !important;
}
