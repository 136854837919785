// sass-lint:disable-all
//Import Required Files

@import 'config';

// Mixins
@import 'utils/mixins/community/breakpoint';

// Shame File
@import 'shame';

.hide-below-1024 {
    display:none !important;

    @include breakpoint('l') {
        display: block !important;
    }
}

.hide-below-960 {
    display:none !important;

    @include breakpoint('m') {
        display: block !important;
    }
}

.hide-below-768 {
    display:none !important;

    @include breakpoint('s') {
        display: block !important;
    }
}

.hide-below-480 {
    display:none !important;

    @include breakpoint('xs') {
        display: block !important;
    }
}

.hide-below-320 {
    display:none !important;

    @include breakpoint('xxs') {
        display: block !important;
    }
}
