/// Mixin to output breakpoint given a variable, sass map reference or value
///
/// @group Media Queries
/// @access public
///
/// @param {Length} $min - Value to assign to min-width within media query
/// @param {Length} $max - Value to assign to max-width within media query
///
/// @example scss - Usage
/// .foo {
///   @include breakpoint(100px) {
///      width: 50px;
///   }
/// }
///
/// .bar {
///   @include breakpoint(200px, 400px) {
///      width: 50px;
///   }
/// }
///
/// @example css - CSS output
///
///  @media (min-width: 100px) {
///    .foo {
///       width: 50px; } }
///
///  @media (min-width: 200px) and (max-width: 400px) {
///    .bar {
///       width: 50px; } }
///
// sass-lint:disable-all

@function parse-breakpoint-string($value) {
    @if $breakpoints {
        @if map-get($breakpoints, $value) {
            @return map-get($breakpoints, $value);
        } @else {
            @return 0;
        }
    }

    @return 0;
}

@mixin breakpoint($min: 0, $max: 0) {
    $type-min: type-of($min);
    $type-max: type-of($max);

    @if $type-min == string {
        $min: (parse-breakpoint-string($min));
    }

    @if $type-max == string {
        $max: parse-breakpoint-string($max);
    }

    $query: 'all' !default;
    @if $min != 0 and $max != 0 {
        $query: '(min-width: #{$min}) and (max-width: #{$max})';
    } @else if $min != 0 and $max == 0 {
        $query: '(min-width: #{$min})';
    } @else if $min == 0 and $max != 0 {
        $query: '(max-width: #{$max})';
    }

    @media #{$query} {
        @content;
    }
}
