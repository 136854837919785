.abs-product-link,
.product.name,
.product-item-name {
    > a {
        &:visited,
        &:hover {
            color: map-get($color, 'red');
        }
    }
}

.abs-product-link > a,
.product-item-name > a,
.product.name a > a {
    color: map-get($color, 'red');
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            color: map-get($color, 'red');
        }
    }
}
